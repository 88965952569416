.pagination {
  margin-top: 10px;

  @media print {
    display: none;
  }

  &__title {
    display: flex;
    text-align: center;
    position: relative;
    margin: 100px 0 20px;

    &-h {
      text-align: center;
      margin: 0 auto;
      padding: 5px 10px;
      background: var(--background);
      color: color-mix(in srgb, var(--accent), transparent 70%);;
      font-size: .8rem;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: .1em;
      z-index: 1;
    }

    hr {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      margin-top: 15px;
      z-index: 0;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    gap: 10px;

    a {
      text-decoration: none;
    }
  }
}

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 0;
  appearance: none;

  @media(var(--phone)) {
    flex: 1;
  }

  a {
    display: flex;
    justify-content: center;
    flex: 1;
    padding: 8px 16px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &.next .button__icon {
    margin-left: 8px;
  }

  &.previous .button__icon {
    margin-right: 8px;
  }
}
